import therapist1 from '@website/assets/img/therapist-1.png';
import therapist2 from '@website/assets/img/therapist-2.jpeg';
import therapist3 from '@website/assets/img/therapist-3.jpeg';
import {Language} from '@website/types/language.types';

export interface Condition {
  title: string;
  desc: string;
  about: string;
  image: string;
  route_param: string;
}

export const conditionsArray: Condition[] = [
  {
    title: 'title1',
    desc: 'desc1',
    about: 'about1',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/adoption_1725534961581.jpg',
    route_param: 'adoption',
  },
  {
    title: 'title2',
    desc: 'desc2',
    about: 'about2',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/self-esteem_1725534966997.jpg',
    route_param: 'self-esteem',
  },
  {
    title: 'title3',
    desc: 'desc3',
    about: 'about3',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/domestic-violence_1725534970105.jpg',
    route_param: 'domestic-violence',
  },
  {
    title: 'title4',
    desc: 'desc4',
    about: 'about4',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/group-therapy_1725534973892.jpg',
    route_param: 'group-therapy',
  },
  {
    title: 'title5',
    desc: 'desc5',
    about: 'about5',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/personality-disorder_1725534975277.jpg',
    route_param: 'personality-disorder',
  },
  {
    title: 'title6',
    desc: 'desc6',
    about: 'about6',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/identity-issues_1725534979053.jpg',
    route_param: 'identity-issues',
  },
  {
    title: 'title7',
    desc: 'desc7',
    about: 'about7',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/multicultural_1725534983886.jpg',
    route_param: 'multicultural',
  },
  {
    title: 'title8',
    desc: 'desc8',
    about: 'about8',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/divorce-recovery_1725534991124.jpg',
    route_param: 'divorce-recovery',
  },
  {
    title: 'title9',
    desc: 'desc9',
    about: 'about9',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/adjustment-disorder_1725535002469.jpg',
    route_param: 'adjustment-disorder',
  },
  {
    title: 'title10',
    desc: 'desc10',
    about: 'about10',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/adolescent-issues_1725535007115.jpg',
    route_param: 'adolescent-issues',
  },
  {
    title: 'title11',
    desc: 'desc11',
    about: 'about11',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/perinatal-mental-health-pregnancy-postpartum-parenting_1725535012795.jpg',
    route_param: 'perinatal-mental-health-pregnancy-postpartum-parenting',
  },
  {
    title: 'title12',
    desc: 'desc12',
    about: 'about12',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/career-issues_1725535017824.jpg',
    route_param: 'career-issues',
  },
  {
    title: 'title13',
    desc: 'desc13',
    about: 'about13',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/bipolar-disorder_1725535028762.jpg',
    route_param: 'bipolar-disorder',
  },
  {
    title: 'title14',
    desc: 'desc14',
    about: 'about14',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/anger-management_1725535030103.jpg',
    route_param: 'anger-management',
  },
  {
    title: 'title15',
    desc: 'desc15',
    about: 'about15',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/anxiety_1725535035381.jpg',
    route_param: 'anxiety',
  },
  {
    title: 'title16',
    desc: 'desc16',
    about: 'about16',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/adhd_1725535038487.jpg',
    route_param: 'adhd',
  },
  {
    title: 'title17',
    desc: 'desc17',
    about: 'about17',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/stress-management_1725535047952.jpg',
    route_param: 'stress-management',
  },
  {
    title: 'title18',
    desc: 'desc18',
    about: 'about18',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/sleep-issues_1725535055578.jpg',
    route_param: 'sleep-issues',
  },
  {
    title: 'title19',
    desc: 'desc19',
    about: 'about19',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/grief-loss_1725535064818.jpg',
    route_param: 'grief-loss',
  },
  {
    title: 'title20',
    desc: 'desc20',
    about: 'about20',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/lgbtq-issues_1725535070969.jpg',
    route_param: 'lgbtq-issues',
  },
  {
    title: 'title21',
    desc: 'desc21',
    about: 'about21',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/romantic-partner-issues_1725535080674.jpg',
    route_param: 'romantic-partner-issues',
  },
  {
    title: 'title22',
    desc: 'desc22',
    about: 'about22',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/ptsd_1725535093333.jpg',
    route_param: 'ptsd',
  },
  {
    title: 'title23',
    desc: 'desc23',
    about: 'about23',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/ocd_1725535098395.jpg',
    route_param: 'ocd',
  },
  {
    title: 'title24',
    desc: 'desc24',
    about: 'about24',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/friend-issues_1725535105155.jpg',
    route_param: 'friend-issues',
  },
  {
    title: 'title25',
    desc: 'desc25',
    about: 'about25',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/family-issues_1725535107279.jpg',
    route_param: 'family-issues',
  },
  {
    title: 'title26',
    desc: 'desc26',
    about: 'about26',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/eating-disorders_1725535108940.jpg',
    route_param: 'eating-disorders',
  },
  {
    title: 'title27',
    desc: 'desc27',
    about: 'about27',
    image:
      'https://storage.googleapis.com/wellnite-prod-image-bucket/depression_1725535110733.jpg',
    route_param: 'depression',
  },
];

export const articlesArray = [
  {
    _id: '1',
    image: therapist1,
    desc: "I have to tell you about the future. Where were we. C'mon, Mom, make it fast, I'll miss my bus. Hey see you tonight, Pop. Woo, time to change that oil. Good evening, I'm Doctor Emmet Brown, I'm standing here on the parking lot of- About how far ahead are you going?",
    title: 'Bottling Up Emotions: How to Let Go',
    tag: 'Providers',
    author: {
      _id: '230',
      name: 'Jorge Daniel Espinosa',
      avatar: therapist1,
    },
    route_param: '',
  },
  {
    _id: '2',
    image: therapist2,
    desc: "Identity issues can significantly impact an individual's mental well-being. Identity formation is a lifelong process influenced by factors such as personal experiences, cultural background, social environment, and life transitions.",
    title: 'Identity Issues',
    tag: 'Providers',
    author: {
      _id: '231',
      name: 'Sarah Brown',
      avatar: therapist2,
    },
    route_param: '',
  },
  {
    _id: '3',
    image: therapist3,
    desc: "No no no, Doc, I just got here, okay, Jennifer's here, we're gonna take the new truck for a spin. A colored mayor, that'll be the day. I think it's terrible. Girls chasing boys. When I was your age I never chased a boy, or called a boy, or sat in a parked car with a boy. Go. Go. Just turn around, McFly, and walk away. Are you deaf, McFly? Close the door and beat it.",
    title: 'Bottling Up Emotions: How to Let Go',
    tag: 'Providers',
    author: {
      _id: '232',
      name: 'Murray Molohon',
      avatar: therapist3,
    },
    route_param: '',
  },
];

export const therapistsArray = [
  {
    image: therapist1,
    alt: 'therapist-1',
  },
  {
    image: therapist2,
    alt: 'therapist-2',
  },
  {
    image: therapist3,
    alt: 'therapist-3',
  },
];

export const languages: Language[] = [
  {
    countryCode: 'US',
    country: 'United States',
    language: 'English',
    code: 'en',
  },
  {countryCode: 'CA', country: 'Canada', language: 'English', code: 'en'},
  {
    countryCode: 'SA',
    country: 'South Africa',
    language: 'English',
    code: 'en',
  },
  {countryCode: 'AU', country: 'Australia', language: 'English', code: 'en'},
  {
    countryCode: 'NZ',
    country: 'New Zealand',
    language: 'English',
    code: 'en',
  },
  {countryCode: 'CO', country: 'Colombia', language: 'Spanish', code: 'es'},
  {countryCode: 'MX', country: 'Mexico', language: 'Spanish', code: 'es'},
  {countryCode: 'CL', country: 'Chile', language: 'Spanish', code: 'es'},
  {countryCode: 'ES', country: 'Spain', language: 'Spanish', code: 'es'},
  // {countryCode: 'BR', country: 'Brazil', language: 'Portugese', code: 'pt'},
];

export const usFAQs = [
  {
    header: 'header1',
    content: 'content1',
  },
  {
    header: 'header2',
    content: 'content2',
  },
  {
    header: 'header3',
    content: 'content3',
  },
  {
    header: 'header4',
    content: 'content4',
  },
  {
    header: 'header5',
    content: 'content5',
  },
  {
    header: 'header6',
    content: 'content6',
  },
  {
    header: 'header7',
    content: 'content7',
  },
  {
    header: 'header8',
    content: 'content8',
  },
  {
    header: 'header9',
    content: 'content9',
  },
  {
    header: 'header10',
    content: 'content10',
  },
  {
    header: 'header11',
    content: 'content11',
  },
  {
    header: 'header12',
    content: 'content12',
  },
  {
    header: 'header13',
    content: 'content13',
  },
  {
    header: 'header14',
    content: 'content14',
  },
  {
    header: 'header15',
    content: 'content15',
  },
  {
    header: 'header16',
    content: 'content16',
  },
  {
    header: 'header17',
    content: 'content17',
  },
  {
    header: 'header18',
    content: 'content18',
  },
  {
    header: 'header19',
    content: 'content19',
  },
  {
    header: 'header20',
    content: 'content20',
  },
  {
    header: 'header21',
    content: 'content21',
  },
  {
    header: 'header22',
    content: 'content22',
  },
  {
    header: 'header23',
    content: 'content23',
  },
  {
    header: 'header24',
    content: 'content24',
  },
  {
    header: 'header25',
    content: 'content25',
  },
  {
    header: 'header26',
    content: 'content26',
  },
  {
    header: 'header27',
    content: 'content27',
  },
  {
    header: 'header28',
    content: 'content28',
  },
  {
    header: 'header29',
    content: 'content29',
  },
];

export const internationalFAQs = [
  {
    header: 'header30',
    content: 'content30',
  },
  {
    header: 'header31',
    content: 'content31',
  },
  {
    header: 'header32',
    content: 'content32',
  },
  {
    header: 'header33',
    content: 'content33',
  },
  {
    header: 'header34',
    content: 'content34',
  },
  {
    header: 'header35',
    content: 'content35',
  },
  {
    header: 'header36',
    content: 'content36',
  },
  {
    header: 'header37',
    content: 'content37',
  },
  {
    header: 'header38',
    content: 'content38',
  },
];

export const usProviderFaqs = [
  {
    header: 'header39',
    content: 'content39',
  },
  {
    header: 'header40',
    content: 'content40',
  },
  {
    header: 'header41',
    content: 'content41',
  },
  {
    header: 'header42',
    content: 'content42',
  },
  {
    header: 'header43',
    content: 'content43',
  },
  {
    header: 'header44',
    content: 'content44',
  },
  {
    header: 'header45',
    content: 'content45',
  },
  {
    header: 'header46',
    content: 'content46',
  },
  {
    header: 'header47',
    content: 'content47',
  },
  {
    header: 'header48',
    content: 'content48',
  },
  {
    header: 'header49',
    content: 'content49',
  },
  {
    header: 'header50',
    content: 'content50',
  },
  {
    header: 'header51',
    content: 'content51',
  },
  {
    header: 'header52',
    content: 'content52',
  },
  {
    header: 'header53',
    content: 'content53',
  },
];

export const intlProviderFaqs = [
  {
    header: 'header54',
    content: 'content54',
  },
  {
    header: 'header55',
    content: 'content55',
  },
  {
    header: 'header56',
    content: 'content56',
  },
  {
    header: 'header57',
    content: 'content57',
  },
  {
    header: 'header58',
    content: 'content58',
  },
  {
    header: 'header59',
    content: 'content59',
  },
  {
    header: 'header60',
    content: 'content60',
  },
  {
    header: 'header61',
    content: 'content61',
  },
];

export const quartetFaqs = [
  {
    header: 'header62',
    content: 'content62',
  },
  {
    header: 'header63',
    content: 'content63',
  },
  {
    header: 'header64',
    content: 'content64',
  },
];

export const defaultImageBlurUrl =
  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/2wBDAQoLCw4NDhwQEBw7KCIoOzs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozs7Ozv/wAARCAAIABADASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwDLsPHUkl+JHPFT+IPEx1VQiUUVDZSR/9k=';
