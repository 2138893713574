"use client";

import React, { FC, useEffect, useState } from "react";
import { Hearts, Oval, ThreeDots } from "react-loader-spinner";

export const LoadingSpinner: FC<{
  color?: string;
  type?: "Hearts" | "Oval" | "ThreeDots";
  height?: number;
  width?: number;
  strokeWidth?: number;
  secondaryColor?: string;
  displayDelay?: number;
}> = ({
  color = "#315eff",
  type = "Hearts",
  height = 50,
  width = height ?? 50,
  strokeWidth = 5,
  secondaryColor = "#ccc",
  displayDelay = 500,
}) => {
  const [displayLoader, setDisplayLoader] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayLoader(true);
    }, displayDelay);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return displayLoader ? (
    <span data-cy="loading" data-testid="loading">
      {type === "Hearts" ? (
        <Hearts color={color} height={height} width={width}></Hearts>
      ) : type === "ThreeDots" ? (
        <ThreeDots color={color} height={height} width={width}></ThreeDots>
      ) : (
        <Oval
          color={color}
          height={height}
          width={width}
          secondaryColor={secondaryColor}
          strokeWidth={strokeWidth}
        ></Oval>
      )}
    </span>
  ) : null;
};
