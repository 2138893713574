'use client';

import React, {CSSProperties, FC, MouseEvent} from 'react';
import classnames from 'classnames';
import {Tooltip} from './Tooltip';
import {LoadingSpinner} from './Loaders';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  fontSize?: 'xs' | 'sm' | 'lg' | 'base';
  borderRadius?: 'none' | 'lg' | 'xl' | 'full';
  borderWidth?: string;
  borderColor?: string;
  bgColor?: string;
  textColor?: string;
  fontWeight?: 'thin' | 'normal' | 'medium' | 'semibold' | 'bold';
  type?: 'button' | 'submit';
  onClick?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => void;
  disabled?: boolean;
  isSubmitting?: boolean;
  form?: string;
  tooltipContent?: string;
  btnType?: 'primary' | 'secondary' | 'danger' | 'black' | 'custom';
  outline?: boolean;
  hover?: boolean;
  loaderType?: 'Hearts' | 'Oval';
  loaderSize?: number;
  loaderSecondaryColor?: string;
  style?: CSSProperties;
}

const getActiveClasses = (classes: string, hover: boolean) => {
  if (hover) return classes;
  return classes
    .split(' ')
    .filter(cls => !cls.startsWith('hover:'))
    .join(' ');
};

export const Button: FC<Props> = ({
  children,
  btnType = 'primary',
  className,
  onClick = undefined,
  disabled = false,
  borderRadius = 'lg',
  bgColor = 'transparent',
  fontSize = 'sm',
  textColor = 'white',
  borderWidth = 'border',
  borderColor = 'white',
  type = 'button',
  fontWeight = 'normal',
  outline = false,
  hover = true,
  tooltipContent,
  isSubmitting = false,
  loaderType = 'Hearts',
  loaderSize = 17,
  loaderSecondaryColor = '#fff',
  ...rest
}) => {
  const customBtnColor = bgColor.split('-')[0];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const btnState: Record<any, any> = {
    false: {
      primary: {
        disabled: `bg-blue-500 bg-opacity-25 text-white border-0 font-${fontWeight} text-${fontSize} rounded-${borderRadius} pointer-event-none`,
        active: getActiveClasses(
          `text-${fontSize} text-white rounded-${borderRadius} border-transparent font-${fontWeight} ${borderWidth} bg-blue-600 hover:bg-white hover:text-blue-600 hover:border-blue-600`,
          hover,
        ),
        common: '',
      },
      secondary: {
        disabled: `bg-mediumGray text-white border-0 text-${fontSize} font-${fontWeight} rounded-${borderRadius} pointer-event-none shadow-lg`,
        active: getActiveClasses(
          `text-${fontSize} text-white rounded-${borderRadius} font-${fontWeight} border-transparent ${borderWidth} bg-primary hover:shadow-lg`,
          hover,
        ),
        common: 'py-[16px] px-[28px] !font-inter font-semibold',
      },
      danger: {
        disabled: `bg-red-500 bg-opacity-25 text-white border-0 text-${fontSize} font-${fontWeight} rounded-${borderRadius} pointer-event-none`,
        active: getActiveClasses(
          `text-${fontSize} text-white rounded-${borderRadius} font-${fontWeight} border-transparent ${borderWidth} bg-red-600 hover:bg-white hover:text-red-600 hover:border-red-600`,
          hover,
        ),
        common: '',
      },
      black: {
        disabled: `bg-black/25 text-white border-0 text-${fontSize} font-${fontWeight} rounded-${borderRadius} pointer-event-none`,
        active: getActiveClasses(
          `text-${fontSize} text-white rounded-${borderRadius} font-${fontWeight} border-transparent ${borderWidth} bg-black hover:bg-transparent hover:text-black hover:border-black`,
          hover,
        ),
        common: '',
      },
      custom: {
        disabled: `bg-${customBtnColor}-500 bg-opacity-25 text-${textColor} font-${fontWeight} text-${fontSize} border-${borderColor} rounded-${borderRadius} pointer-event-none`,
        active: `text-${fontSize} text-${textColor} rounded-${borderRadius} font-${fontWeight} border-${borderColor} ${borderWidth} bg-${bgColor}`,
        common: '',
      },
    },
    true: {
      primary: {
        disabled: `bg-blue-500 bg-opacity-25 text-white border-0 text-${fontSize} font-${fontWeight} rounded-${borderRadius} pointer-event-none`,
        active: getActiveClasses(
          `text-${fontSize} rounded-${borderRadius} ${borderWidth} font-${fontWeight} hover:bg-blue-600 hover:text-white hover:border-transparent bg-white text-blue-600 border-blue-600`,
          hover,
        ),
        common: '',
      },
      danger: {
        disabled: `bg-red-500 bg-opacity-25 text-white border-0 text-${fontSize} font-${fontWeight} rounded-${borderRadius} pointer-event-none`,
        active: getActiveClasses(
          `text-${fontSize} rounded-${borderRadius} ${borderWidth} font-${fontWeight} hover:bg-red-600 hover:text-white hover:border-transparent bg-white text-red-600 border-red-600`,
          hover,
        ),
        common: '',
      },
      black: {
        disabled: `bg-black/25 text-white border-0 text-${fontSize} font-${fontWeight} rounded-${borderRadius} pointer-event-none`,
        active: getActiveClasses(
          `text-${fontSize} rounded-${borderRadius} ${borderWidth} font-${fontWeight} hover:bg-black hover:text-white hover:border-black bg-${bgColor} text-black border-gray-400`,
          hover,
        ),
        common: '',
      },
      custom: {
        disabled: `bg-${customBtnColor}-500 bg-opacity-25 text-${textColor} font-${fontWeight} text-${fontSize} rounded-${borderRadius} pointer-event-none`,
        active: `text-${fontSize} text-${textColor} rounded-${borderRadius} font-${fontWeight} border-${borderColor} ${borderWidth} bg-${bgColor}`,
        common: '',
      },
    },
  };

  const inactive = isSubmitting || disabled;
  const btnClasses =
    (inactive
      ? btnState[String(outline)][btnType].disabled
      : btnState[String(outline)][btnType].active) +
    ' ' +
    btnState[String(outline)][btnType].common;

  return (
    <>
      {isSubmitting && loaderType === 'Hearts' ? (
        <div className="flex justify-center m-2">
          <LoadingSpinner type={loaderType} />
        </div>
      ) : null}
      {inactive && tooltipContent ? (
        <Tooltip
          content={
            <div className="text-center py-1 font-light">
              <p>{tooltipContent}</p>
            </div>
          }
          maxWidth={250}
          theme="light-border"
        >
          <button
            type="button"
            className={`leading-snug ${btnClasses} ${className?.replaceAll(
              'hover',
              '',
            )}`}
          >
            {children}
          </button>
        </Tooltip>
      ) : (
        <Tooltip
          content={
            <div className="text-center py-1 font-light">
              <p>{tooltipContent}</p>
            </div>
          }
          disabled={!tooltipContent}
          theme="light-border"
        >
          <button
            {...rest}
            type={type}
            {...(!inactive && {onClick})}
            disabled={inactive}
            className={classnames(
              `${className} transition-all leading-snug ${btnClasses}`,
            )}
          >
            {isSubmitting && loaderType === 'Oval' ? (
              <LoadingSpinner
                type={loaderType}
                width={loaderSize}
                height={loaderSize}
                secondaryColor={loaderSecondaryColor}
                {...(btnType === 'black' && {color: 'black'})}
              />
            ) : (
              children
            )}
          </button>
        </Tooltip>
      )}
    </>
  );
};
