import React from "react";

export const ChevronDownIcon = () => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.46463L1.4592 0L7.5 6.07073L13.5408 0L15 1.46463L7.5 9L0 1.46463Z"
        fill="currentColor"
      />
    </svg>
  );
};
